<template>
    <div id="voting">
        <div class="flex items-center flex-col p-4"
             v-if="voting">

            <img src="/images/missyrockz_logo.png" alt="Logo" class="w-24 mb-4">

            <h1 class="text-gray-700 text-3xl md:text-5xl md:text-6xl mb-6 text-bodoni-moda title">{{ voting.headline }}</h1>

            <span class="w-16 md:w-32 border border-gray-700"></span>

            <p class="text-2xl text-roboto w-full md:w-1/2 text-center mb-10">{{ voting.subline }}</p>

            <form method="post" @submit.prevent="vote" v-if="!loading && !voted">
                <div class="flex items-center flex-col" v-if="step === 1">
                    <div class="mb-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 49 49">
                            <g id="Gruppe_420" data-name="Gruppe 420" transform="translate(-134 -132)">
                                <text id="_1." data-name="1." transform="translate(153 159)" fill="#272727" font-size="24" font-family="Bodoni Moda" letter-spacing="0.02em"><tspan x="0" y="0">1.</tspan></text>
                                <g id="Gruppe_419" data-name="Gruppe 419">
                                    <circle id="Ellipse_12" data-name="Ellipse 12" cx="24.5" cy="24.5" r="24.5" transform="translate(134 132)" fill="#db5188"/>
                                </g>
                                <text id="_1.-2" data-name="1." transform="translate(153 165)" fill="#fff" font-size="24" font-family="Bodoni Moda" letter-spacing="0.02em"><tspan x="0" y="0">1.</tspan></text>
                            </g>
                        </svg>
                    </div>

                    <p class="text-2xl text-roboto-thin w-full md:w-1/2 text-center mb-10">
                        Jetzt für Dein Lieblingsshirt abstimmen!
                    </p>

                    <div class="text-2xl text-roboto w-full text-center">
                        <div class="flex flex-wrap justify-center w-full articles">
                            <a href="#"
                               v-for="(item, index) in voting.items"
                               @click.prevent="setItem(item.id)"
                               class="relative pr-3 pb-3"
                               :class="[item.width]"
                               :key="index">
                                <span class="flex items-center justify-center absolute top-0 right-3 w-14 h-14 bg-gray-200"
                                      :class="{'active': form.item_id === item.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.97" height="22.087" viewBox="0 0 22.97 22.087">
                                      <path id="Ionicons_ios_heart_empty" data-name="Ionicons ios heart empty" d="M64.786,56h-.055a6.283,6.283,0,0,0-5.246,2.871A6.283,6.283,0,0,0,54.239,56h-.055A6.243,6.243,0,0,0,48,62.239a13.441,13.441,0,0,0,2.639,7.327,46.254,46.254,0,0,0,8.846,8.52,46.254,46.254,0,0,0,8.846-8.52,13.441,13.441,0,0,0,2.639-7.327A6.243,6.243,0,0,0,64.786,56Zm2.3,12.656a42.351,42.351,0,0,1-7.6,7.487,42.413,42.413,0,0,1-7.6-7.493,11.912,11.912,0,0,1-2.341-6.411A4.685,4.685,0,0,1,54.2,57.552h.05a4.629,4.629,0,0,1,2.269.6,4.825,4.825,0,0,1,1.679,1.574,1.551,1.551,0,0,0,2.6,0,4.873,4.873,0,0,1,1.679-1.574,4.629,4.629,0,0,1,2.269-.6h.05a4.685,4.685,0,0,1,4.649,4.688A12.063,12.063,0,0,1,67.083,68.656Z" transform="translate(-48 -56)" fill="#262626"/>
                                    </svg>
                                </span>
                                <img :src="item.image" :alt="item.name">
                            </a>
                        </div>
                    </div>
                </div>

                <div class="flex items-center flex-col" v-else-if="step === 2 && !alreadyVoted">
                    <div class="mb-8">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 49 49">
                            <g id="Gruppe_404" data-name="Gruppe 404" transform="translate(-134 -132)">
                                <text id="_1." data-name="1." transform="translate(153 159)" fill="#272727" font-size="24" font-family="Bodoni Moda" letter-spacing="0.02em"><tspan x="0" y="0">1.</tspan></text>
                                <circle id="Ellipse_12" data-name="Ellipse 12" cx="24.5" cy="24.5" r="24.5" transform="translate(134 132)" fill="#db5188"/>
                                <text id="_2." data-name="2." transform="translate(151 165)" fill="#fff" font-size="24" font-family="Bodoni Moda" letter-spacing="0.02em"><tspan x="0" y="0">2.</tspan></text>
                            </g>
                        </svg>
                    </div>

                    <div class="mb-4 w-full md:w-96">
                        <label :class="{'valid': validEmail}">
                            <input name="name"
                                   type="email"
                                   class="w-full border border-gray-700 text-roboto outline-none p-4"
                                   placeholder="DEINE EMAIL ADRESSE"
                                   v-model="form.email"
                                   v-on:keydown.enter.prevent
                                   required>
                        </label>
                        <small v-if="errors && errors.email" class="text-red-500 text-sm">
                            {{ errors.email[0] }}
                        </small>
                    </div>

                    <div class="mb-8 w-full md:w-2/5 px-4">

                        <label class="block left-label text-roboto-thin">
                            <input type="checkbox" name="data_protection" v-model="form.data_protection">
                            <span>
                                <small>Hiermit erklärst du dich als Nutzer damit einverstanden, dass deine persönlichen Daten von
                                uns als Betreiber in elektronischer Form gespeichert werden – siehe
                                <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung</router-link>.
                                Du stimmst zu, dass die im Rahmen der Registrierung erhobenen Daten und die weiteren von
                                dir eingegebenen Daten in einer Datenbank gespeichert werden – siehe
                                <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung</router-link>
                                . Es erfolgt keine Datenspeicherung und Datennutzung ohne ein an anderer Stelle von dir ausdrücklich eingeholtes Einverständnis.
                                </small>
                            </span>
                        </label>

                    </div>

                    <button type="submit"
                            class="w-full md:w-96 text-gray-700 border border-gray-700 uppercase px-16 py-4 text-roboto text-sm mb-4"
                            :class="{'button-not': !form.data_protection || errors || !validEmail}">
                        Jetzt Abstimmen
                    </button>

                    <p class="mb-4">-- oder --</p>

                    <button type="button"
                            @click="step = 1"
                            class="w-full md:w-96 text-gray-700 border border-gray-700 uppercase px-16 py-4 text-roboto text-sm mb-20">
                        Zurück zur Auswahl
                    </button>
                </div>
            </form>

            <div v-if="voted" class="flex items-center flex-col p-4">
                <p class="text-3xl md:text-5xl text-roboto-thin w-full text-center mb-10">
                    Woho!🎉<br>
                    Vielen Dank für deine Stimme.🤗
                    <br><br>
                    Wie geht‘s weiter?
                    <br><br>
                    Wir benachrichtigen dich per Mail, sobald es Neuigkeiten gibt.
                    <br><br>
                    Stay tuned
                    <br><br>
                    ROCKZ GREET‘Z💋<br>
                    🌈your MISSY
                </p>
            </div>

            <div v-if="alreadyVoted" class="flex items-center flex-col p-4">
                <p class="text-3xl md:text-5xl text-roboto-thin w-full text-center mb-10">
                    Was ist hier los?
                    <br><br>
                    Du Schlingel hast bereits abgestimmt!😛
                    <br><br>
                    Damit das Endergebnis nicht verfälscht wird, würden wir uns freuen, wenn jeder ROCKZ FAN lediglich eine Stimme abgibt.
                    <br><br>
                    Thank you 🙏
                    <br><br>
                    Bis Bald
                    <br><br>
                    Deine MISSY😘
                </p>
            </div>
        </div>

        <div class="flex items-center flex-col p-4" v-if="!voting || loading">
            <div class="sk-cube-grid">
                <div class="sk-cube sk-cube1"></div>
                <div class="sk-cube sk-cube2"></div>
                <div class="sk-cube sk-cube3"></div>
                <div class="sk-cube sk-cube4"></div>
                <div class="sk-cube sk-cube5"></div>
                <div class="sk-cube sk-cube6"></div>
                <div class="sk-cube sk-cube7"></div>
                <div class="sk-cube sk-cube8"></div>
                <div class="sk-cube sk-cube9"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Votings",
    data() {
        return {
            voting: null,
            form: {
                voting_id: null,
                item_id: null,
                email: null,
                data_protection: false,
            },
            step: 1,
            voted: false,
            validEmail: false,
            alreadyVoted: false,
            loading: false,
            errors: null,
        }
    },
    watch: {
        'form.email'(email) {
            let validate = email.toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )

            this.validEmail = !!validate;
        }
    },
    methods: {
        getVoting() {
            let voting = this.$route.params.voting

            window.axios.get(`/votings`, {
                params: {
                    slug: voting
                }
            })
                .then(response => {
                    this.voting = response.data
                    this.form.voting_id = response.data.id
                })
                .catch(error => {
                    console.log(error.response)
                })
        },

        setItem(id) {
            if (!this.form.item_id) {
                this.form.item_id = id
                this.step = 2
                window.scrollTo(0, 0)
            } else if (this.form.item_id && id !== this.form.item_id) {
                this.form.item_id = id
                this.step = 2
                window.scrollTo(0, 0)
            } else {
                this.form.item_id = null
            }
        },

        vote() {
            if (!this.validEmail) {
                this.errors = {
                    email: ['Email nicht korrekt']
                };
            } else {
                this.loading = true

                window.axios.post('/votings/vote', this.form)
                    .then(() => {
                        this.voted = true
                        this.loading = false
                    })
                    .catch(error => {
                        if (error.response.status === 303) {
                            this.alreadyVoted = true
                            this.loading = false
                        }
                    })
            }
        }
    },
    mounted() {
        this.getVoting()
    }
}
</script>

<style scoped>
.articles a span.active {
    background-color: #E42167 !important;
}
</style>